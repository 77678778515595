var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticStyle:{"width":"auto"},attrs:{"app":"","color":"#f5f5f5","dense":"","dark":"","absolute":"","small":""}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',{staticStyle:{"color":"red"},domProps:{"textContent":_vm._s(_vm.$t(_vm.$route.name))}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.reColor()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"amber"}},[_vm._v("mdi-theme-light-dark")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("setting.darkMode")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.langChang()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"light-blue darken-1"}},[_vm._v("mdi-translate")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("setting.lang")))])]),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"0","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.profile),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }